<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :extra-validation="extraValidation"
    :extra-actions="extraActions"
    return-to-list
  >
    <EntityEditSection
      :module="config.module"
      :entity.sync="entity"
      :form-fields="fields"
      allow-autofocus
    />
  </EntitySingle>
</template>

<script>
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { getUserRights } from '@/auth/utils'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from './storeModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {},
      fields: [],
    }
  },
  computed: {
    pollId() {
      return this.$route.params.pid
    },
    isManager() {
      return getUserRights().includes('Poll_Management_Poll_Edit')
    },
    blankEntity() {
      return {
        poll: { id: this.pollId },
        answers: {},
        options: [],
      }
    },
    config() {
      return {
        store: storeModule,
        module: 'poll-responses',
        endpoint: 'poll-responses',
        route: 'voting',
        title: {
          single: this.$t('Voting Add'),
          plural: this.$t('My Votings'),
        },
      }
    },
    extraActions() {
      return [
        {
          callback: this.resetEntity,
          icon: 'eraser',
          title: 'Clear',
          insert: true,
        },
      ]
    },
  },
  async mounted() {
    this.resetEntity()
    this.fetchFields()
  },
  methods: {
    async fetchFields() {
      this.$store
        .dispatch('app-common/fetchPollFields', this.pollId)
        .then(response => {
          this.fields = response.data.map(field => {
            return {
              id: field.id,
              parent: 'answers',
              type: 'checkboxgroup',
              options: field.options,
              label: field.title,
              required: field.required,
              optionsCnt: field.optionsCnt,
              extraClass: 'label-lg',
              size: 'lg',
              colSize: 6,
              helpText: this.stripHtml(field.description),
            }
          })
        })
    },
    resetEntity() {
      this.entity = JSON.parse(JSON.stringify(this.blankEntity))
    },
    extraValidation() {
      Object.values(this.entity.answers).forEach(selections => {
        this.entity.options = [...this.entity.options, ...selections.map(selection => ({ id: selection }))]
      })
      return ''
    },
  },
}
</script>
